<template>
  <div class="flex-column">
    <div class="flex-column" v-if="data.info">
      <div style="position: fixed; width: 100%">
        <div class="choose-house flex-row" @click="changeCommunity">
          <img src="@/assets/house_mini.png" alt="" class="house-mini-icon" />
          <div class="addr">{{ data.info[data.currentIndex].name }}</div>
          <img src="@/assets/mini_right.png" alt="" class="mini-right" />
        </div>
      </div>
      <div style="height: 66px"></div>

      <van-empty
        :image="require('@/assets/no_inspection_report.png')"
        description="暂无验收档案~"
        v-if="data.info[data.currentIndex].houseList <= 0"
      />

      <div
        class="flex-column item"
        v-for="(item, index) in data.info[data.currentIndex].houseList"
        :key="index"
      >
        <div class="flex-row" @click="expandItem(index)">
          <div class="point"></div>
          <div class="flex-column" style="margin-left: 8px; flex-grow: 1">
            <div class="house-info">{{ item.houseName }}</div>
            <div class="name-info">{{ item.ownerName }}，{{ item.ownerPhone }}</div>
          </div>
          <img src="@/assets/right_up.png" alt="" class="right-action" v-if="item.expand" />
          <img src="@/assets/right_down.png" alt="" class="right-action" v-else />
        </div>

        <template v-if="item.expand">
          <div class="line"></div>
          <div
            @click="previewPdf(doc.url)"
            class="file-item flex-column file-wrapper"
            v-for="(doc, docIndex) in item.docList"
            :key="docIndex"
          >
            <div class="flex-row">
              <img src="@/assets/pdf_icon.png" alt="" class="pdf-icon" />
              <div class="file-name">{{ doc.name }}</div>
            </div>
            <div class="file-size">{{ doc.size }}</div>
          </div>
        </template>
      </div>
      <!-- <img
        src="@/assets/video_preview.png"
        alt=""
        class="video"
        @click="previewVideo(data.info[data.currentIndex].videoUrl)"
        v-if="data.info[data.currentIndex].videoUrl"
      /> -->
      <img
        src="@/assets/big_preview.png"
        alt=""
        class="big-preview"
        @click="previewVideo(data.info[data.currentIndex].videoUrl)"
        v-if="data.info[data.currentIndex].videoUrl"
      />
      <div style="height: 20px"></div>

      <van-action-sheet
        v-model:show="data.show"
        :actions="data.columns"
        cancel-text="取消"
        @select="onSelect"
      />
    </div>

    <van-empty
      :image="require('@/assets/no_inspection_report.png')"
      description="暂无验收档案~"
      v-if="data.noData"
    />
  </div>
</template>

<script>
import { page } from 'project-common-mobile'
import { onMounted } from '@vue/runtime-core'
import { reactive } from '@vue/reactivity'
import { getCheckList } from '@/api/main'
import { openPage } from '@/jsbridage'

export default {
  name: 'InspectionReport',
  setup() {
    const data = reactive({
      show: false,
      columns: [],
      info: null,
      currentIndex: 0,
      noData: false,
    })
    onMounted(
      page(() => {
        getCheckList()
          .then((res) => {
            if (res.code == 200) {
              let d = res.data

              d.forEach((element, index) => {
                element.name = element.communityName
                element.index = index
              })
              data.columns = d
              data.info = d
              data.noData == !d || d.length <= 0
            }
          })
          .catch(() => {
            data.noData = true
          })
      }, data)
    )
    const expandItem = (index) => {
      let expand = data.info[data.currentIndex].houseList[index].expand
      data.info[data.currentIndex].houseList[index].expand = !expand
    }

    const onSelect = (item) => {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      data.show = false
      if (data.currentIndex == item.index) {
        return
      }
      data.currentIndex = item.index
    }

    const changeCommunity = () => {
      data.show = true
    }
    const previewPdf = (url) => {
      let pageUrl = `https://pdf-js.antaishenghuo.com/?file=${url}`
      openPage(pageUrl)
    }
    const previewVideo = (url) => {
      // navigateTo({
      //   path: '/videoPreview',
      //   query: {
      //     url: url,
      //   },
      // })
      let pageUrl = `${window.location.origin}/videoPreview/?url=${url}`
      openPage(pageUrl)
    }

    return { data, expandItem, onSelect, changeCommunity, previewPdf, previewVideo }
  },
}
</script>
<style lang="scss" scoped>
.big-preview {
  margin-top: 20px;
  width: 90%;
  align-self: center;
  // height: 44px;
}
.video {
  width: 85px;
  height: 43px;
  position: fixed;
  right: 0;
  bottom: 156px;
}
.right-action {
  margin-top: 5px;
  width: 10px;
  height: 7px;
}
.pdf-icon {
  width: 30px;
  height: 34px;
  flex-shrink: 0;
  margin-right: 12px;
}
.file-wrapper {
  padding: 16px;
  margin-top: 10px;
  background: #f8f8f8;
  border-radius: 8px;
}
.file-name {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #808080;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 18px;
  overflow: hidden;
}
.file-size {
  margin-left: 42px;
  font-size: 11px;
  font-family: PingFangSCRegular;
  color: #9f9f9f;
  line-height: 11px;
}
.choose-house {
  height: 66px;
  background-color: white;
  align-items: center;
  padding: 0px 16px;
}
.addr {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3e3e3e;
  line-height: 26px;
  margin-left: 6px;
  flex-grow: 1;
}
.house-mini-icon {
  height: 18px;
  width: 18px;
}
.mini-right {
  width: 6px;
  height: 10px;
}
.point {
  width: 4px;
  height: 10px;
  margin-top: 2px;
  background: #1db74d;
}
.name-info {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3e3e3e;
  line-height: 18px;
}
.house-info {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3e3e3e;
  line-height: 15px;
}
.item {
  margin: 10px 16px 0px 16px;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
}
.line {
  margin-top: 10px;
  height: 1px;
  background-color: #f1f1f1;
}
.van-empty__image {
  width: 113px;
  height: 113px;
}
</style>
