import { Http } from 'athena-core'

/**
 * 通用Http请求传入参数定义
 */
let httpParams = {
  baseURL: `${process.env.VUE_APP_SERVER}`,
  isDebug: `${process.env.VUE_APP_IS_DEBUG}`,
  isPage: false,
  isWeb: false,
}

/**
 * 初始化Http请求服务
 */
const http = new Http().init(httpParams)

export default http
