export const openPage = (url) => {
  try {
    try {
      window.testInterface.openWebPage(url)
    } catch (ex) {
      window.webkit.messageHandlers.openWebPage.postMessage(url)
    }
  } catch (ex) {
    console.log('请在app内打开!')
    return false
  }
}
